import React, { useState } from 'react'
import usePost from '../api/usePost';


const Request = ({request_data, notify, setRefresh, refresh}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const audioRef = React.createRef();
    const [showSnooze, setShowSnooze] = useState(false);
    const [snoozeComment, setSnoozeComment] = useState("")

    const { postData, error } = usePost();

    const playAudio = () => {
        if (!isPlaying) {
          audioRef.current.play();
          setIsPlaying(true);
        } else {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setIsPlaying(false);
        }
      };

    const toggleContent = () => {
      setShowSnooze(false)
        setShowContent(!showContent)
      };

      const toggleSnooze = () => {
        setShowContent(false)
        setShowSnooze(!showSnooze)
      };

      const handleChange = (e) => {
        console.log(e.target.value)
        setSnoozeComment(e.target.value);
      };

      function formatFriendlyDate(timestamp) {
        const date = new Date(timestamp);
        const currentDate = new Date();
      
        if (isSameDay(date, currentDate)) {
          return 'Today ' + formatTime(date);
        } else if (isYesterday(date, currentDate)) {
          return 'Yesterday ' + formatTime(date);
        } else {
          return formatDate(date, 'dddd, MMMM D, H:mm');
        }
      }
      
      function formatTime(date) {
        return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
      }
      
      function formatDate(date, format) {
        return date.toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
      }
      
      function isSameDay(date1, date2) {
        return date1.getDate() === date2.getDate() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getFullYear() === date2.getFullYear();
      }
      
      function isYesterday(date1, date2) {
        const yesterday = new Date(date2);
        yesterday.setDate(date2.getDate() - 1);
        return isSameDay(date1, yesterday);
      }

      const delete_interaction = async () => {
        const data = {interaction_id: request_data.id}
        const response = await postData('/delete_interaction', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            notify(response.message)
            setRefresh(!refresh)
        }
      }

      const archive_interaction = async () => {
        const data = {interaction_id: request_data.id}
        const response = await postData('/archive_interaction', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            notify(response.message)
            setRefresh(!refresh)
        }
      }

      const snooze_interaction = async (time, snoozeComment) => {
        const data = {interaction_id: request_data.id, time: time, snooze_comment: snoozeComment}
        console.log(data)
        const response = await postData('/snooze_interaction', data);
        if (response) {
            notify(response.message)
            setRefresh(!refresh)
        }
      }

      const mark_interaction_as_converted = async () => {
        const data = {interaction_id: request_data.id}
        const response = await postData('/mark_interaction_as_converted', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            notify(response.message)
            setRefresh(!refresh)
        }
      }

      const mark_interaction_as_lost = async () => {
        const data = {interaction_id: request_data.id}
        const response = await postData('/mark_interaction_as_lost', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            notify(response.message)
            setRefresh(!refresh)
        }
      }
    
    
  return (
    <div className='request'>
        <div className='request-summary'>
        <div className='request-summary'>
        {request_data.status === 'snoozed' && <i className="fas fa-clock space" ></i>}
        {request_data.snooze_comment && <i className="fas fa-comment space" title={request_data.snooze_comment} ></i>}
        <span className='request-date'>{formatFriendlyDate(request_data.date_time)}</span>
        {request_data.type === 'phone_call' && <i className="fas fa-phone"></i>}
        {request_data.type === 'website_message' && <i className="fas fa-envelope"></i>}
        {request_data.type === 'phone_text' && <i className="fas fa-sms"></i>}
        {request_data.type === 'google_ads_phone_text' && <><i className="fab fa-google space"></i><i className="fas fa-sms"></i></>}
        {request_data.type === 'google_ads_phone_call' && <><i className="fab fa-google space"></i><i className="fas fa-phone"></i></>}
        {request_data.type === 'google_ads_lead' && <i class="fab fa-google"></i>}
        </div>
        {request_data.person.first_name && `${request_data.person.first_name} ${request_data.person.last_name} : `} {request_data.person.phone_number}
        <div className='icons'>
            {request_data.file_url && <i onClick={playAudio} className={`click fas fa-${isPlaying ? 'stop' : 'play'}`}></i>}
            {request_data.content && <i onClick={toggleContent} className={`click fas fa-caret-${showContent ? 'up' : 'down'}`}></i>}
            {request_data.file_url && (
            <audio ref={audioRef}>
            <source src={request_data.file_url} type="audio/mp3" />
            Your browser does not support the audio element.
            </audio>
        )}
        <i title="Lead Has Booked Appointment" onClick={mark_interaction_as_converted} className="click action green fas fa-check-square"></i>
        <i title="To Follow Up" onClick={toggleSnooze} className="click action yellow fas fa-clock"></i>
        <i title="Lead Will Not Book" onClick={mark_interaction_as_lost} className="click action red fas fa-window-close"></i>
        <i title="Lead Was Already Client" onClick={archive_interaction} className="click action fas fa-user-check"></i>
        <i title="Spam / Irrelevant" onClick={delete_interaction} className="click action fas fa-trash"></i>
      </div>
      </div>
      {request_data.content && showContent &&
      <div className='request-content'>{request_data.content}</div>
    }
    {showSnooze &&
    <>
        <form className='snooze-form'>
            <textarea rows="5" cols="100" placeholder='Add Comment Before Snoozing' onChange={handleChange}></textarea>
        </form>
        <div className='request-snooze'>
        <div className='snooze-choice' onClick={() => snooze_interaction(1, snoozeComment)}>
          Snooze for 1 Day
        </div>
      </div>
      <div className='request-snooze'>
        <div className='snooze-choice' onClick={() => snooze_interaction(3, snoozeComment)}>
          Snooze for 3 Days
        </div>
        <div className='snooze-choice' onClick={() => snooze_interaction(7, snoozeComment)}>
        Snooze for One Week
        </div>
        <div className='snooze-choice' onClick={() => snooze_interaction(14, snoozeComment)}>
        Snooze for Two Weeks
        </div>
        <div className='snooze-choice' onClick={() => snooze_interaction(30, snoozeComment)}>
        Snooze for One Month
        </div>
      </div>
    </>
    }
    </div>
  )
  // snooze_interaction
}

export default Request