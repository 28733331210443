import React, { useEffect, useState } from "react";
import useFetch from "../api/useFetch";
import Table from "./Table";
import Loading from "./Loading";

const Clients = () => {
    const { result: clients, isLoading: clientsLoading, error: clientsError } = useFetch(`/clients`, []);
    const tableHeader = [
      { content: "Name", size: "lg", isLink: false },
      { content: "Location", size: "sm", isLink: false },
      { content: "Type", size: "sm", isLink: false },
      { content: "NB Appointments", size: "sm", isLink: false },
      { content: "Last Appointment", size: "sm", isLink: false },
      { content: "Has Future Appointment", size: "sm", isLink: false },
      { content: "Total Spent", size: "sm", isLink: false },
      { content: "Details", size: "sm", isLink: true, link: "/client" },
      { content: "Select", size: "sm", isLink: false },
    ];
    const [tableContent, setTableContent] = useState(null);

    useEffect(() => {
        if (clients !== null) {
            setTableContent(clients.map((client) => ([
                client.person?.first_name + " " + client.person?.last_name,
                client.location?.name,
                client.alf_client ? "ALF" : client.event_client ? "Event" : "Home",
                client.appointment_count,
                client.last_appointment_date,
                client.has_future_appointment ? <i className="green fas fa-check"></i> : <i className="red fas fa-times"></i>,
                "$" + client.total_spent,
                client.id,
                <input type="checkbox" />
            ])));
        }
    }, [clients]);


    return (
        <div className="list">
          <div className="title">
            CLIENTS
          </div>
          {clients && (
            <Table tableHeader={tableHeader} tableContent={tableContent} />
          )}
          {clientsLoading && <Loading />}
          {clientsError && <>{clientsError} - Please contact Florent</>}
        </div>
      );

};

export default Clients;
