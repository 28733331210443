import React, { useEffect } from "react";
import useFetch from "../api/useFetch";
import { useState } from "react";
import Table from "./Table";
import Loading from "./Loading";

const Technicians = () => {
  const { result: technicians, isLoading: techniciansLoading, error: techniciansError } = useFetch('/technicians', []);
  const tableHeader = [{ content: "First Name", size: "md", isLink: false}, { content: "Last Name", size: "md", isLink: false}, { content: "Status", size: "md", isLink: false}, { content: "Location", size: "md", isLink: false}, { content: "Details", size: "sm", isLink: true, link: '/technician'}];
  const [tableContent, setTableContent] = useState(null);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    if (technicians !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        technicians.filter((item) => showInactive ? item.internal_status === 'inactive' : item.internal_status === 'active').map((item) => [
          item.person.first_name,
          item.person.last_name,
          item.internal_status,
          item.location && item.location.name,
          item.id
        ])
      );
    }
  }, [technicians, showInactive]);
  

  return (
    <>
        <div div className="list">
          <div className="title">
            TECHNICIANS
          </div>
          <div className="filter">
            <center>
            <button style={{backgroundColor: '#930000', fontWeight: 'bold', fontFamily: 'Montserrat', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer'}}  onClick={() => setShowInactive(!showInactive)}>{showInactive ? 'Show Active' : 'Show Inactive'}</button>
            </center>
          </div>
          <br />
          <br />
          <br />
          <br />
      {technicians && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {techniciansLoading && <Loading />}
      {techniciansError && <>{techniciansError}</>}
      </div>
    </>
  );
};

export default Technicians;
