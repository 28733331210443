import React, { useState } from "react";
import useFetch from "../api/useFetch";
import Loading from "./Loading";
import usePost from "../api/usePost";


const EventForm = ({ appointment_id, notify }) => {

    const [technician, setTechnician] = useState(null);
  const { postData } = usePost();
  const [refresh, setRefresh] = useState(false);
  const [payout, setPayout] = useState(null);
  const { result: technicians, isLoading: techniciansLoading, error: techniciansError } =
    useFetch("/technicians", [refresh]);

  const addTechnician = async () => {
    const data = { appointment_id: appointment_id, technician_id: technician, payout: payout };
    const response = await postData("/add_event_technician", data);
    if (response) {
      notify(response.message);
      setRefresh(!refresh);
    }
  };


  return (
    <>
      {techniciansLoading && <Loading />}
      {techniciansError && <>{techniciansError}</>}
      {technicians && (
        <>
          <div style={{ marginTop: "25px" }} className="buttons">
            <div className="button">
              <div>Add Technician</div>
              <br />
              <br />
              <form>
                <select
                  value={technician}
                  onChange={(e) => setTechnician(e.target.value)}
                >
                  <option value="" disabled>
                    Select Technician
                  </option>
                  {technicians?.filter((item) => item.internal_status === "active").map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.person.first_name} {item.person.last_name}
                    </option>
                  ))}
                </select>
                <br />
                <br />
                <label>Payout</label>
                <br />
                <br />
                <input type="text" placeholder="Payout" value={payout} onChange={(e) => setPayout(e.target.value)} />
                <br />
                <br />
                <button onClick={addTechnician}>Save</button>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventForm;
