import React from 'react';
import { useContext } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import SidebarContext from '../context/SidebarContext';
import Dashboard from './Dashboard';
import Logo from '../static/img/amn_logo_simplified.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Technicians from './Technicians';
import Locations from './Locations';
import Requests from './Requests';
import Appointments from './Appointments';
import Appointment from './Appointment';
import Technician from './Technician';
import Client from './Client'; 
import Clients from './Clients';
import Services from './Services';
import Payrolls from './Payrolls';
import Payroll from './Payroll';
import Location from './Location';
const Main = () => {

    const { collapsed } = useContext(SidebarContext);
    console.log(collapsed)

    const notify = (text, status="success") => {
        if (status === 'success') {
        toast.success(text, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "custom-toast",
        });}
        else {
            toast.error(text, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "custom-toast",
              });
        }
      };

    return (
        <div>
            <div className={`rectangle ${collapsed ? 'collapsed' : ''}`}>
            {((window.location.hostname === 'localhost') &&
                    (<Link to ="/"><div className="logo"><img src="https://www.pngfind.com/pngs/m/170-1706361_web-development-icon-web-development-logo-png-transparent.png" alt="Development Logo" width="50px" /></div></Link>))
            ||
                (<Link to ="/"><div className="logo"><img src={Logo} alt="Amour Mobile Nails Logo" width="65px" /></div></Link>)
            }
            </div>
        <Topbar />
        <Sidebar />
            <div className={`main ${collapsed ? 'collapsed' : ''}`}>
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/technicians" element={<Technicians />} />
                    <Route exact path="/locations" element={<Locations />} />
                    <Route exact path="/location/:id" element={<Location />} />
                    <Route exact path="/requests" element={<Requests notify={notify}/>} />
                    <Route exact path="/appointments" element={<Appointments />} />
                    <Route exact path="/services" element={<Services />} />
                    <Route exact path="/payroll" element={<Payrolls />} />
                    <Route exact path="/payroll/:id" element={<Payroll notify={notify} />} />
                    <Route exact path="/appointment/:id" element={<Appointment notify={notify} />} />
                    <Route exact path="/client/:id" element={<Client />} />
                    <Route exact path="/clients" element={<Clients />} />
                    <Route exact path="/technician/:id" element={<Technician notify={notify}/>} />

                </Routes>
            </div>
            <ToastContainer   autoClose={2000} hideProgressBar />
        </div>
    );
};

export default Main;