import { useParams } from "react-router-dom";
import useFetch from "../api/useFetch";
import Loading from "./Loading";

const Location = () => {
  const { id } = useParams();
  const { result: location, isLoading: locationLoading, error: locationError } = useFetch(`/location/${id}`, []);

  // Default coordinates for Florida (adjust as needed)

  return (
    <>
      {location && (
        <div className="list">
          <div className="title">{location.name}</div>
          {/* Map */}

        </div>
      )}
      {locationLoading && <Loading />}
      {locationError && <>{locationError}</>}
    </>
  );
};

export default Location;
