import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import Loading from "./Loading";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Technician = ({notify}) => {
  const { postData, isLoading } = usePost();
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const { result: item, isLoading: itemLoading, error: itemError } = useFetch(`/technician/${id}`, [refresh]);
  const [formData, setFormData] = useState({technician_id: null, first_name: null, last_name: null, phone_number: null, email: null, location_id: null});

  useEffect(() => {
    // Check if technician is loaded and not null
    if (item) {
      // Update formData with the technician details
      setFormData({
        technician_id: item.technician.id || null,
        first_name: item.technician.person.first_name || null,
        last_name: item.technician.person.last_name || null,
        phone_number: item.technician.person.phone_number || null,
        email: item.technician.person.email || null,
        location_id: item.technician.location?.id || null,
        internal_status: item.technician.internal_status || null
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === 'location_id' ? parseInt(value, 10) : value;
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting formData:", formData); // <--- Add this
    const response = await postData('/update_technician', formData);
    if (response) {
        notify('Technician Updated Succesfully')
        setRefresh(!refresh);
      
    }
  };

  return (
    <>
        <div div className="list">
        <div className="title">
          TECHNICIAN
        </div>
      {item && (
        <>
        <form onSubmit={handleSubmit}>
          <div>
            <center>
            <label htmlFor="first_name">First Name</label>
            <br />
            <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange}/>
            <br /><br />
            <label htmlFor="last_name">Last Name</label>
            <br />
            <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange}/>
            <br /><br />
            <label htmlFor="phone_number">Phone Number</label>
            <br />
            <input type="text" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange}/>
            <br /><br />
            <label htmlFor="email">Email</label>
            <br />
            <input type="text" id="email" name="email" value={formData.email} onChange={handleChange}/>
            <br /><br />
            <label htmlFor="internal_status">Status</label>
            <br />
            <select name="internal_status" id="internal_status" value={formData.internal_status} onChange={handleChange}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <br /><br />
            <label htmlFor="location">Location</label>
            <br />
            <select name="location_id" id="location_id" value={formData.location_id ?? ''} onChange={handleChange}>
            <option value="" disabled>Select a location</option>
            {item.locations.map((location) => (
              <option key={location.id} value={location.id}>{location.name}</option>
            ))}
          </select>

            <br />
            <br />
            <button type="submit" style={{backgroundColor: '#930000', fontWeight: 'bold', fontFamily: 'Montserrat', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer'}}>
              <i className="fas fa-save" disabled={isLoading}></i> Update
            </button>
            </center>
          </div>
        </form>
        
        </>
      )}
      {itemLoading && <Loading />}
      {itemError && <>{itemError}</>}
      </div>
    </>
  );
};

export default Technician;
