import { useParams } from 'react-router-dom';
import useFetch from "../api/useFetch";
import Loading from "./Loading";
import Table from "./Table";
import funcs from '../utils/utils'
import { useState, useEffect } from "react";


const Client = () => {
    const { id } = useParams();
    const { result: item, isLoading: itemLoading, error: itemError } = useFetch(`/client/${id}`, []);
    const tableHeader = [
      { content: "Client", size: "md", isLink: true, link: '/client' },
      { content: "Technician", size: "md", isLink: true, link: '/technician' },
      { content: "Date", size: "md", isLink: false },
      { content: "Type", size: "md", isLink: false },
      { content: "Discount", size: "md", isLink: false },
      { content: "Expected Revenue", size: "md", isLink: false },
      { content: "Expected Profit", size: "md", isLink: false },
      { content: "Paid", size: "md", isLink: false },
      { content: "Amount Paid", size: "md", isLink: false },
      { content: "Expected Match", size: "md", isLink: false },
      { content: "Tip", size: "md", isLink: false },
      { content: "Technician Payout", size: "md", isLink: false },
      { content: "Details", size: "md", isLink: true, link: '/appointment' }
    ];
    const [tableContent, setTableContent] = useState(null);

    useEffect(() => {
      if (item !== null) {
        setTableContent(
          item.appointments.filter(item => item.client.person && item.client.person.is_senior_facility === false).sort((a,b) => new Date(a.date_time) - new Date(b.date_time)).map((item) => [
              [item.client && item.client.person && `${item.client.person.first_name ? item.client.person.first_name : '' } ${item.client.person.last_name ? item.client.person.last_name : '' }`,item.client.id],
              [item.technician && item.technician.person && item.technician.person.first_name,item.technician.id],
              funcs.displayDate(item.date_time),
              item.is_alf ? 'ALF' : item.is_event ? 'Event' : 'Home',
              item.discount ? `$${item.discount}` : '-',
              `$${item.expected_revenue}`,
              `$${item.expected_profit}`,
              item.is_paid,
              item.total_payment ? `$${item.total_payment}` : '-',
              item.total_payment === item.expected_revenue ? true : false,
              item.technician_tip ? `$${item.technician_tip}` : '-',
              item.technician_payout ? `$${item.technician_payout}` : '-',
              item.id
          ])
        );
      }
    }, [item]);
    return (
        <div className="list">
          <div className="title">
            CLIENT
          </div>
          {item && (
          <center>
              <div>
                <b>First Name</b><br />{item.client.person && item.client.person.first_name ? item.client.person.first_name : ''}
              </div>
              <div>
                <b>Last Name</b><br />{item.client.person.last_name ? item.client.person.last_name : ''}
              </div>
              <div>
                <b>Phone Number</b><br /> {item.client.person && item.client.person.phone_number}
              </div>
              <div>
                <b>Email</b><br /> {item.client.person && item.client.person.email}
              </div>
              </center>)}
              <div className="title">
            APPOINTMENTS
          </div>
            {item && (
              <Table tableHeader={tableHeader} tableContent={tableContent} />
            )}
          {itemLoading && <Loading />}
          {itemError && <>{itemError} - Please contact Florent</>}
        </div>
      );

};

export default Client;
